<template>
  <v-card rounded="lg" flat max-width="500" class="pa-md-12 pa-4 pb-md-5 width100" min-height="448">
    <div v-if="card == 'login'">
      <div class="f32 mb-2">Travel.it Back-office</div>
      <div class="mb-11 text--text">Welcome back to the platform</div>
      <v-form @submit.prevent="login" ref="form" lazy-validation>
        <v-text-field
          class="field46"
          v-model="email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46"
          v-model="password"
          placeholder="Password"
          :error-messages="passwordErrors"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="text-center">
          <v-btn depressed type="submit" block large color="primary">Log In</v-btn>
        </div>
        <div class="text-center mt-8">
          <v-btn to="/forgot" depressed text color="primary">I forgot my password</v-btn>
        </div>
      </v-form>
    </div>
    <div v-if="card == 'OTP'">
      <div class="primary--text text--lighten-1 mb-7 link" @click="card = 'login'">
        <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
      </div>
      <div class="f32 mb-2">Second-factor authentication</div>
      <div class="mb-5 text--text">Please enter OTP from your 2FA application or device</div>
      <v-text-field
        class="field46"
        v-model="otp"
        placeholder="OTP code"
        :error-messages="otpErrors"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
      <div class="text-center">
        <v-btn @click="otpRequest" depressed block large color="primary">Confirm</v-btn>
      </div>
      <div class="text-center mt-8">
        <v-btn @click="card = 'recovery'" depressed text color="primary">Can not get OTP?</v-btn>
      </div>
    </div>
    <div v-if="card == 'recovery'">
      <div class="primary--text text--lighten-1 mb-7 link" @click="card = 'login'">
        <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
      </div>
      <div class="f32 mb-2">Second-factor authentication recovery</div>
      <div class="mb-8 text--text">Enter the recovery code</div>
      <v-text-field
        class="field46"
        v-model="otpR"
        placeholder="Recovery code"
        :error-messages="otpRecErrors"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
      <v-btn @click="otpRecRequest" depressed block large color="primary">Submit</v-btn>
      <div class="text-center mt-6">
        <v-btn @click="card = 'OTP'" depressed text color="primary">Use OTP code</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      card: this.$route.query.c || 'login',
      email: '',
      password: '',
      otp: '',
      otpR: '',
      show: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'invalid') {
        this.$notify({
          title: 'Provided email and password pair is not correct',
          message: 'Please check the provided email and password and try again',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async login() {
      this.error = [];
      const data = new Object();
      data.email = this.email;
      data.password = this.password;
      await this.$store
        .dispatch('signIn', data)
        .then((res) => {
          if (res.data.auth_token) {
            localStorage.setItem('jwt_token', res.data.auth_token);
            this.card = 'OTP';
          } else if (res.data.access_token) {
            this.done(res);
          }
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    async otpRequest() {
      this.error = [];
      const data = new Object();
      data.code = this.otp;
      await this.$store
        .dispatch('signInOtp', data)
        .then((res) => {
          this.done(res);
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    async otpRecRequest() {
      this.error = [];
      const data = new Object();
      data.code = this.otpR;
      await this.$store
        .dispatch('signInRecovery', data)
        .then((res) => {
          this.done(res);
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    done(res) {
      localStorage.setItem('jwt_token', res.data.access_token);
      setTimeout(() => {
        this.$store.dispatch('getProfile').then(() => {
          this.$router.push(this.$route.query.from || '/');
        });
      }, 500);
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'credential__invalid')) {
        this.notifi('invalid');
      } else if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/blocked');
      } else if (this.error.find((item) => item == 'email__not_confirmed')) {
        this.$router.push('/confirm-email');
      } else if (this.error.find((item) => item == 'action__limit_exceeded')) {
        this.$router.push('/sign-in-limit');
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'email__not_confirmed') && errors.push('Provided email is not confirmed');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    otpErrors() {
      const errors = [];
      this.error.find((item) => item == 'code__required') && errors.push('Please enter your otp code');
      this.error.find((item) => item == 'code__invalid') && errors.push('Provided otp code is not valid');
      return errors;
    },
    otpRecErrors() {
      const errors = [];
      this.error.find((item) => item == 'recovery_code__required') && errors.push('Please enter your recovery code');
      this.error.find((item) => item == 'recovery_code__invalid') &&
        errors.push('The recovery code is not correct. You have two attempts more');
      return errors;
    },
  },
};
</script>
